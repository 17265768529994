import {
  accountsColumns,
  actionColumns as allActionColumns,
  defaultFixedColumns as allDefaultFixedColumns,
  defaultScrollingColumns as allDefaultScrollingColumns,
  allSelectedColumnOptions,
  localStorageKey as localStorageKeyAccounts,
} from '@/pages/Accounts/constants';

import {
  computed, onMounted, ref, watch,
} from 'vue';

import { Constants } from '@jumpcloud/ui-components';
import { snakeCase } from 'lodash';
import { useAccountsStore } from '@/stores/Accounts';
import { useLoadingState } from '@/util/useLoadingState';

import moment from 'moment';
import type { AccountListItem } from '@/api/shared/types';
import type { Accounts } from '@/api/Accounts';

const { sortDirections } = Constants;
const defaultSortBy = 'username';
const defaultSortDirection = sortDirections.ascending;

type AccountListItemWithSelected = AccountListItem & Partial<{ selected: boolean }>;

type SelectedColumnOption = {
  name: string;
  value: string;
};

type SortDirection = 'ascending' | 'descending';

type Filter = {
  relatedField: string;
  name: string;
  value: string;
};

type AppFilter = {
  label: string;
  value: string;
};

export function useSaasAccountsTable() {
  const selectedOwnerOptions = ref<Filter[]>([]);
  const selectedApps = ref<AppFilter[]>([]);
  const selectedFindings = ref<Filter[]>([]);
  const selectedLoginMethods = ref<Filter[]>([]);
  const selectedDiscoveryMethods = ref<Filter[]>([]);
  const selectedDiscoveryDate = ref();
  const selectedLastUsed = ref();

  const { isLoading, runAsyncWithLoading } = useLoadingState();
  const accountsStore = useAccountsStore();
  const accountsData = computed<Accounts>(() => accountsStore.accounts);
  const accountsTableFilters = computed(() => accountsStore.accountsTableFilters);

  const selectAll = ref(false);
  const indeterminateSelectAll = ref(false);
  const searchValue = ref('');
  const tableData = ref<AccountListItemWithSelected[]>(accountsData?.value.accounts || []);

  const appliedFilters = ref<Filter[]>([]);
  const currentPageNumber = ref(0);
  const rowsPerPage = ref(50);
  const totalCount = computed(() => accountsData.value?.count || 0);
  const sortBy = ref(defaultSortBy);
  const sortDirection = ref<SortDirection>(defaultSortDirection);

  const actionDropdownItems = ref([]);

  const localStorageKey = ref(localStorageKeyAccounts);
  const columns = ref(accountsColumns);
  const actionColumns = ref(allActionColumns);
  const defaultFixedColumns = ref(allDefaultFixedColumns);
  const defaultScrollingColumns = ref(allDefaultScrollingColumns);

  const sortValue = computed(() => (sortDirection.value === sortDirections.ascending
    ? snakeCase(sortBy.value)
    : `-${snakeCase(sortBy.value)}`));

  const handlePageChange = ({
    currentPageNum: newCurrentPageNum,
    rowsPerPage: newRowsPerPage,
  }: {
    currentPageNum: number;
    rowsPerPage: number;
  }) => {
    currentPageNumber.value = newCurrentPageNum;
    rowsPerPage.value = newRowsPerPage;
  };

  const selectedColumnOptions = ref(allSelectedColumnOptions);

  const handleSelect = (selected: AccountListItemWithSelected) => {
    const selectedId = selected.appId;
    tableData.value = tableData.value?.map((item) => {
      if (item.objectId === selectedId) {
        return { ...item, selected: !item?.selected };
      }
      return item;
    });
    if (tableData.value.every((item) => item?.selected)) {
      selectAll.value = true;
      indeterminateSelectAll.value = false;
    } else if (tableData.value.some((item) => item?.selected)) {
      selectAll.value = false;
      indeterminateSelectAll.value = true;
    } else {
      selectAll.value = false;
      indeterminateSelectAll.value = false;
    }
  };

  const handleSelectAll = (selected: boolean) => {
    tableData.value = tableData.value?.map((item) => ({
      ...item,
      selected,
    }));
    selectAll.value = selected;
    indeterminateSelectAll.value = false;
  };

  const handleUpdateColumnOptions = (value: SelectedColumnOption[]) => {
    selectedColumnOptions.value = value;
  };

  const fetchAccounts = async () => {
    const fetchAccountsCallback = async () => {
      await accountsStore.fetchAccounts(accountsTableFilters.value);
    };

    await runAsyncWithLoading(fetchAccountsCallback);
  };

  const handleSortColumn = ({
    dataFieldName,
    sortDirection: newSortDirection,
  }: {
    dataFieldName: string;
    sortDirection: SortDirection;
  }) => {
    sortBy.value = dataFieldName;
    sortDirection.value = newSortDirection;
  };

  watch(
    [currentPageNumber, searchValue, appliedFilters, rowsPerPage, sortValue],
    async (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      [newCurrentPageNumber, newSearchValue, newAppliedFilters, newRowsPerPage, newSortValue],
      [oldCurrentPageNumber],
    ) => {
      const pageStart = newCurrentPageNumber * newRowsPerPage;
      if (newCurrentPageNumber === oldCurrentPageNumber && newCurrentPageNumber !== 0) {
        currentPageNumber.value = 0;
        return;
      }

      accountsStore.setAccountsTableFilters({
        search: newSearchValue,
        skip: newCurrentPageNumber !== oldCurrentPageNumber ? pageStart : 0,
        limit: newRowsPerPage,
        sort: newSortValue,
        user: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Owner')
          .map((filter) => filter.value),
        application: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Application')
          .map((filter) => filter.value),
        findings: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Risks')
          .map((filter) => filter.value),
        loginMethod: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Login Methods')
          .map((filter) => filter.value),
        source: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Sources')
          .map((filter) => filter.value),
        lastUsed: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Last Used')
          .map((filter) => filter.value) as [],
        discoveryDate: newAppliedFilters
          .filter((filter) => filter.relatedField === 'Discovery Date')
          .map((filter) => filter.value) as [],
      });

      await fetchAccounts();
    },
  );

  const handleApplyFilters = () => {
    appliedFilters.value = [
      ...selectedOwnerOptions.value.map((filter: Filter) => ({
        relatedField: 'Owner',
        name: filter.name,
        value: filter.value,
      })),
      ...selectedApps.value.map((filter) => ({
        relatedField: 'Application',
        name: filter.label,
        value: filter.value,
      })),
      ...selectedFindings.value.map((filter: Filter) => ({
        relatedField: 'Risks',
        name: filter.name,
        value: filter.value,
      })),
      ...selectedLoginMethods.value.map((filter: Filter) => ({
        relatedField: 'Login Methods',
        name: filter.name,
        value: filter.value,
      })),
      ...selectedDiscoveryMethods.value.map((filter: Filter) => ({
        relatedField: 'Sources',
        name: filter.name,
        value: filter.value,
      })),
    ];

    if (selectedLastUsed.value) {
      appliedFilters.value.push({
        relatedField: 'Last Used',
        name: `${moment(selectedLastUsed.value.start).format(
          'LLL',
        )} -> ${moment(selectedLastUsed.value.end).format('LLL')}`,
        value: selectedLastUsed.value,
      });
    }

    if (selectedDiscoveryDate.value) {
      appliedFilters.value.push({
        relatedField: 'Discovery Date',
        name: `${moment(selectedDiscoveryDate.value.start).format(
          'LLL',
        )} -> ${moment(selectedDiscoveryDate.value.end).format('LLL')}`,
        value: selectedDiscoveryDate.value,
      });
    }
  };

  watch(accountsData, (newValue) => {
    tableData.value = newValue.accounts;
  });

  const handleRouteQuery = () => {
    // TODO: New overview page will be redirecting there with query params

    handleApplyFilters();
  };

  onMounted(async () => {
    accountsStore.setAccountsTableFilters({
      search: searchValue.value,
      skip: 0,
      limit: rowsPerPage.value,
      sort: sortValue.value,
    });
    handleRouteQuery();
  });

  const handleClearAllFilters = () => {
    selectedOwnerOptions.value = [];
    selectedApps.value = [];
    selectedFindings.value = [];
    selectedLoginMethods.value = [];
    selectedDiscoveryMethods.value = [];
    selectedLastUsed.value = null;
    selectedDiscoveryDate.value = null;

    appliedFilters.value = [];
  };

  const handleRemoveFilter = (filter: Filter) => {
    if (filter.relatedField === 'Owner') {
      selectedOwnerOptions.value = selectedOwnerOptions.value.filter(
        (selectedOwner) => selectedOwner.value !== filter.value,
      );
    } else if (filter.relatedField === 'Application') {
      selectedApps.value = selectedApps.value.filter(
        (selectedApp) => selectedApp.value !== filter.value,
      );
    } else if (filter.relatedField === 'Risks') {
      selectedFindings.value = selectedFindings.value.filter(
        (selectedFinding) => selectedFinding.value !== filter.value,
      );
    } else if (filter.relatedField === 'Login Methods') {
      selectedLoginMethods.value = selectedLoginMethods.value.filter(
        (loginMethod) => loginMethod.value !== filter.value,
      );
    } else if (filter.relatedField === 'Sources') {
      selectedDiscoveryMethods.value = selectedDiscoveryMethods.value.filter(
        (discoveryMethod) => discoveryMethod.value !== filter.value,
      );
    } else if (filter.relatedField === 'Last Used') {
      selectedLastUsed.value = null;
    } else if (filter.relatedField === 'Discovery Date') {
      selectedDiscoveryDate.value = null;
    }

    appliedFilters.value = appliedFilters.value.filter(
      (appliedFilter) => appliedFilter.value !== filter.value,
    );
  };

  const exportData = async () => {
    await accountsStore.exportData(accountsTableFilters.value);
  };

  const exportAllData = async () => {
    await accountsStore.exportAllData();
  };

  return {
    accountsData,
    appliedFilters,
    currentPageNumber,
    fetchAccounts,
    handleApplyFilters,
    handleClearAllFilters,
    handlePageChange,
    handleRemoveFilter,
    handleSelect,
    handleSelectAll,
    handleSortColumn,
    handleUpdateColumnOptions,
    indeterminateSelectAll,
    isLoading,
    rowsPerPage,
    searchValue,
    selectAll,
    selectedColumnOptions,
    sortBy,
    sortDirection,
    tableData,
    totalCount,
    actionDropdownItems,
    actionColumns,
    defaultFixedColumns,
    defaultScrollingColumns,
    columns,
    localStorageKey,
    exportData,
    exportAllData,
    selectedOwnerOptions,
    selectedApps,
    selectedFindings,
    selectedLoginMethods,
    selectedDiscoveryMethods,
    selectedDiscoveryDate,
    selectedLastUsed,
  };
}
