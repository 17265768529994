<script setup>
import {
  DISCOVERY_METHOD_OPTIONS,
  FINDING_OPTIONS,
  LOGIN_METHODS_OPTIONS,
} from '@/pages/Accounts/constants';
import {
  DatePickerRangeInput,
  SelectChipGroup,
} from '@jumpcloud/ui-components';
import ChooseAppFromCatalog from '@/components/ManualApp/ChooseAppFromCatalog.vue';
import SaasUserSelection from '@/components/SaasUserSelection.vue';

defineProps({
  selectedOwnerOptions: {
    type: Array,
    default: () => [],
  },

  selectedApps: {
    type: Array,
    default: () => [],
  },

  selectedFindings: {
    type: Array,
    default: () => [],
  },

  selectedDiscoveryMethods: {
    type: Array,
    default: () => [],
  },

  selectedLoginMethods: {
    type: Array,
    default: () => [],
  },

  selectedLastUsed: {
    type: String,
    default: '',
  },

  selectedDiscoveryDate: {
    type: String,
    default: '',
  },
});

defineEmits([
  'update:selectedApps',
  'update:selectedFindings',
  'update:selectedDiscoveryMethods',
  'update:selectedLoginMethods',
  'update:selectedOwnerOptions',
  'update:selectedLastUsed',
  'update:selectedDiscoveryDate',
]);

const loginMethodOptions = LOGIN_METHODS_OPTIONS.map(
  (item) => ({
    value: item.value,
    name: item.name,
    disabled: false,
  }),
);

const discoveryMethodOptions = DISCOVERY_METHOD_OPTIONS.map(
  (item) => ({
    value: item.value,
    name: item.name,
    disabled: false,
  }),
);

const findingOptions = FINDING_OPTIONS.map(
  (item) => ({
    value: item.value,
    name: item.name,
    disabled: false,
  }),
);
</script>

<template>
  <div class="container">
    <div class="container mb-4">
      <SaasUserSelection
        :closeOnSelect="false"
        headerText="Owners"
        multiple
        :selectedOwnerOptions="selectedOwnerOptions"
        @update:selected-owner-options="$emit('update:selectedOwnerOptions', $event)"
      />
      <ChooseAppFromCatalog
        :closeOnSelect="false"
        :discovered="true"
        :hasMarginBotton="false"
        isFullWidth
        label="Applications"
        multiple
        placeholder="Search applications"
        :required="false"
        :selectedApp="selectedApps"
        :showCustomApp="false"
        @select-app="$emit('update:selectedApps', $event)"
      />
      <SelectChipGroup
        :isFullWidth="false"
        label="Risks"
        :modelValue="selectedFindings"
        :options="findingOptions"
        @update:model-value="$emit('update:selectedFindings', $event)"
      />
      <SelectChipGroup
        :isFullWidth="false"
        label="Login Methods"
        :modelValue="selectedLoginMethods"
        :options="loginMethodOptions"
        @update:model-value="$emit('update:selectedLoginMethods', $event)"
      />
      <SelectChipGroup
        :isFullWidth="false"
        label="Discovery Methods"
        :modelValue="selectedDiscoveryMethods"
        :options="discoveryMethodOptions"
        @update:model-value="$emit('update:selectedDiscoveryMethods', $event)"
      />
    </div>
    <DatePickerRangeInput
      label="Last Used"
      :minuteIncrement="15"
      :modelValue="selectedLastUsed"
      positionFixed
      preventFutureDates
      showTimePicker
      showValidationMessage
      :useUtcTime="false"
      @update:model-value="$emit('update:selectedLastUsed', $event)"
    />
    <DatePickerRangeInput
      label="Discovery Date"
      :minuteIncrement="15"
      :modelValue="selectedDiscoveryDate"
      positionFixed
      preventFutureDates
      showTimePicker
      showValidationMessage
      :useUtcTime="false"
      @update:model-value="$emit('update:selectedDiscoveryDate', $event)"
    />
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  gap: var(--jc-spacer);
}
</style>
